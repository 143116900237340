<template>
  <div>
    <el-card class="box-card card-contanier">
      <!-- <div slot="header" class="clearfix card-header">
        <span>事件操作</span>
      </div> -->
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item>
            <el-input
              v-model="searchForm.username"
              size="medium"
              placeholder="名称"
              clearable
              @keyup.enter.native="getRoleList"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button @click="getRoleList" size="medium">搜索</el-button>
          </el-form-item>

          <el-form-item>
            <el-button
              type="primary"
              @click="dialogVisible = true"
              size="medium"
              >新增</el-button
            >
          </el-form-item>
          <!-- <el-form-item>
            <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
              <el-button
                size="medium"
                type="danger"
                slot="reference"
                :disabled="delBtlStatu"
                v-if="hasAuth('sys:user:delete')"
              >批量删除</el-button>
            </el-popconfirm>
          </el-form-item> -->
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '10px' }"
      :cell-style="{ padding: '1px 0' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        type="index"
        label="序号"
        width="55"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="rname"
        label="名称"
        width="220"
        align="center"
      ></el-table-column>
      <!-- <el-table-column
        prop="code"
        label="唯一编码"
        show-overflow-tooltip
        align="center"
      ></el-table-column> -->
      <el-table-column
        prop="reamrk"
        label="描述"
        show-overflow-tooltip
        align="center"
      ></el-table-column>

      <el-table-column
        prop="ranks"
        label="级别"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.ranks === 0" type="success"
            >平台级别</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.ranks === 1" type="success"
            >商盟级别</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.ranks === 2" type="success"
            >商户级别</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.ranks === 3" type="success"
            >门店级别</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="statu" label="状态" width="200px" align="center">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.statu === 1" type="success"
            >正常</el-tag
          >
          <el-tag size="small" v-else-if="scope.row.statu === 0" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        prop="icon"
        width="220px"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="permHandle(scope.row.roleId, scope.row.rname)"
            >分配权限</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <el-button type="text" @click="editHandler(scope.row.roleId)"
            >编辑</el-button
          >
          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm
              title="确认删除？"
              @confirm="delHandle(scope.row.roleId)"
            >
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!--新增对话框-->
    <el-dialog
      :title="titleName"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editForm"
        label-width="100px"
        class="demo-editForm"
      >
        <el-form-item label="角色名称" prop="rname" label-width="100px">
          <el-input v-model="editForm.rname" autocomplete="off"></el-input>
        </el-form-item>

        <!-- <el-form-item label="唯一编码" prop="code" label-width="100px">
          <el-input v-model="editForm.code" autocomplete="off"></el-input>
        </el-form-item> -->

        <el-form-item label="描述" prop="reamrk" label-width="100px">
          <el-input v-model="editForm.reamrk" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          v-if="this.ranks === 0"
          label="级别"
          prop="ranks"
          label-width="100px"
        >
          <el-radio v-model="editForm.ranks" :label="1" disabled >商盟级别</el-radio>
        </el-form-item>
        <el-form-item
          v-if="this.ranks === 1"
          label="级别"
          prop="ranks"
          label-width="100px"
        >
          <el-radio v-model="editForm.ranks" :label="2" disabled >商户级别</el-radio>
        </el-form-item>
        <el-form-item
          v-if="this.ranks === 2"
          label="级别"
          prop="ranks"
          label-width="100px"
        >
          <el-radio v-model="editForm.ranks" :label="3" disabled >门店级别</el-radio>
        </el-form-item>
        <el-form-item label="状态" prop="statu" label-width="100px">
          <el-radio-group v-model="editForm.statu">
            <el-radio :label="1">正常</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="permName"
      :visible.sync="permDialogVisible"
      width="600px"
      :close-on-click-modal="false"
    >
      <el-form :model="permForm">
        <el-tree
          :data="permTreeData"
          show-checkbox
          ref="permTree"
          :default-expand-all="true"
          node-key="menuId"
          :check-strictly="checkStrictly"
          :props="defaultProps"
        ></el-tree>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="permDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPermFormHandle('permForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/user";
export default {
  name: "Roles",
  data() {
    return {
      searchForm: {},
      delBtlStatu: true,

      total: 20,
      size: 10,
      current: 1,

      tableData: [],
      dialogVisible: false,
      editForm: {
        rname: "",
        statu: 1,
        ranks:"",
        reamrk:''
      },
      editFormRules: {
        rname: [{ required: true, message: "请输入角色名称", trigger: "blur" }],
        // code: [{ required: true, message: "请输入唯一编码", trigger: "blur" }],
        statu: [{ required: true, message: "请选择状态", trigger: "blur" }],
        ranks: [{ required: true, message: "请选择级别", trigger: "blur" }],
      },
      multipleSelection: [],

      permDialogVisible: false,
      permForm: {},
      defaultProps: {
        children: "children",
        label: "name",
      },
      permTreeData: [],
      titleName: "新增角色", //新增标题
      permName: "", //分配权限标题
      ranks: 0, //0：平台级  1：商盟级  2：商户级  3：门店级

      checkStrictly:false,
    };
  },
  created() {
    this.getRoleList();

    api.menuList().then((res) => {
      this.permTreeData = res.data.data.result;
    });
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleClick(row) {
      console.log(row);
    },
    handleSizeChange(val) {
      this.size = val;
      this.getRoleList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getRoleList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0;
    },
    getRoleList() {
      let params = {
        name: this.searchForm.username,
        current: this.current,
        size: this.size,
      };
      api.roleList(params).then((res) => {
        this.tableData = res.data.data.result.records;
        this.total = res.data.data.result.total;
        this.size = res.data.data.result.size;
        this.current = res.data.data.result.current;
        this.ranks = res.data.data.sysUser.ranks;
        this.editForm.ranks=res.data.data.sysUser.ranks+1
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let updateOrSave = this.editForm.roleId ? "update" : "save";
          api.changeRoles(updateOrSave, this.editForm).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                title: "提示信息",
                message: "操作成功",
                type: "success",
                position: "bottom-right",
              });
              this.getRoleList();
              this.editForm = {};
              this.dialogVisible = false;
              this.titleName = "新增角色";
            }
          });
        } else {
          return false;
        }
      });
    },
    editHandler(id) {
      api.getRoleInfo(id).then((res) => {
        console.log(res);
        this.editForm = res.data.data.result;
        this.titleName = this.editForm.rname + "-编辑";
        this.dialogVisible = true;
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
      this.editForm.rname="";
      this.editForm.reamrk="";
      this.titleName = "新增角色";
    },
    handleClose() {
      this.resetForm("editForm");
    },
    delHandle(id) {
      let ids = [];
      if (id) {
        ids.push(id);
      } else {
        this.multipleSelection.forEach((row) => {
          ids.push(row.id);
        });
      }

      api.deleteRoleInfo(ids).then((res) => {
        this.$notify({
          title: "prefect",
          message: res.data.message,
          type: "success",
          position: "bottom-right",
        });
        this.getRoleList();
      });
    },
    permHandle(id, rname) {
      this.permDialogVisible = true;
      this.checkStrictly=true
      this.permName = rname + "-分配权限";
      api.getOneRoleInfo(id).then((res) => {
        this.$refs.permTree.setCheckedKeys(res.data.data.result.menuIds);
        this.checkStrictly=false
        this.permForm = res.data.data.result;
      });
    },
    submitPermFormHandle(formName) {
     
      let menuIds = this.$refs.permTree.getCheckedKeys();
      let menuIds1=this.$refs.permTree.getHalfCheckedKeys()
      api.updatePerm(this.permForm.roleId, [...menuIds,...menuIds1]).then((res) => {
        this.$notify({
          title: "提示信息",
          message: "操作成功",
          type: "success",
          position: "bottom-right",
        });
        this.getRoleList();
        this.permDialogVisible = false;
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
</style>
